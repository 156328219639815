.cardWrapper {
  display: inline-block;
  position: relative;
}

.layer {
  position: relative;
  bottom: 30px;
  left: 5px;
  height: 60px;
  width: 60px;
  margin-bottom: -28px;
}