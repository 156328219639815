* {
  margin: 0;
  padding: 0;
}

.swiper-slide {
  width: auto;
}

.quill {
  display: flex;
  flex-direction: column-reverse; 
  border-top: 1px solid #ccc;
  width: 100%;
}

.scrollList::-webkit-scrollbar {
  height: 10px
}

.scrollList::-webkit-scrollbar-track {
  background: darkgrey;        /* color of the tracking area */
  border-radius: 20px;
}

.scrollList::-webkit-scrollbar-thumb {
  background-color: rgb(122, 120, 120);    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
}

#ql-stroke{
  fill: none;
  stroke: white;
}

#ql-header{
  color: white
}

#ql-fill{
  fill: white;
  stroke: white
}

#ql-blank::before{
  color: white
}

#ql-picker-options{
  background-color: #121212;
}